<script>
import {
  required,
  email,
  minLength,
  // sameAs,
  maxLength,
  minValue,
  maxValue,
  numeric,
  // url,
  // alphaNum,
} from "vuelidate/lib/validators";

import Swal from "sweetalert2";
import useNetw from "@useNetw";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";

/**
 * Form validation component
 */
export default {
  mounted() {
    // this.loading = true;
    // setTimeout(() => {
    //   this.loading = false;
    // }, 100);
  },
  components: {
    Layout,
    PageHeader,
    Multiselect,
    DatePicker,
    /*Switches,
    NumberInputSpinner,*/
  },
  page: {
    title: "แก้ไขข้อมูลลูกค้า",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },

  created() {
    // `this` points to the vm instance
    this.getLocalData();
    this.getProvinces();
    this.getPrefixes();
    this.getDataUpdate();
    this.getAddress();
    this.getOcc();
    // this.getProvincesU();
    this.startIndex = 1;
    this.endIndex = this.perPage;
    this.paginatedData = this.rowAddress.slice(this.startIndex, this.endIndex);
  },

  data() {
    return {
      // Hide: false,
      submitform: false,
      submitformAddress: false,
      submitUpdateAddress: false,
      modalUpdateAddress: false,

      title: "แก้ไขข้อมูลลูกค้า",
      items: [
        {
          text: "ลูกค้าสัมพันธ์",
        },
        {
          text: "ข้อมูลลูกค้า",
          href: "/customer/maincus",
        },
        {
          text: "แก้ไขข้อมูลลูกค้า",
          active: true,
        },
      ],
      localDataBranch:[], //เป็น-array Id
      localData:[],


      value: null,
      value1: null,
      dataTest: [],
      options: [],
      startIndex:1,
      endIndex:1,

      loading: undefined,
      total_pages: "",
      page: "",
      per_page: "",
      totalRecord: "",
      rowData: [], //? Data ที่ต่อ API
      // rowUser: [],
      rowAddress: [],
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      paginatedData: "",

      selectedUsers: [],
      isSelectedAll: false,

      selectMode: "multi",
      selected: [],

      fields: [

        {
          key: "buildingNumber",
          sortable: true,
          label: "บ้านเลขที่",
        },
        {
          key: "districtNameTh",
          sortable: true,
          label: "ตำบล",
        },
        {
          key: "amphurNameTh",
          sortable: true,
          label: "อำเภอ",
        },
        {
          key: "provinceNameTh",
          sortable: true,
          label: "จังหวัด",
        },
        {
          key: "action",
          label: "แก้ไข | ลบ ",
        },
      ],

      error: "กรุณาตรวจสอบข้อมูลอีกครั้ง.",
      // loading: undefined,
      prefixOPtion: [],
      occuOptions:[],
      residentTypeOptions: [
        {
          resId: "R",
          nameTh: "เช่า"
        },
        {
          resId: "H",
          nameTh: "อาศัยอยู่กับญาติ"
        },
        {
          resId: "RH",
          nameTh: "อาศัยอยู่บ้านตัวเอง"
        },
        {
          resId: "OH",
          nameTh: "อาศัยอยู่กับผู้อื่น"
        },
      ],
      maritalStatusOptions:[
        {
          marId: "S",
          nameTh: "โสด"
        },{
          marId: "M",
          nameTh: "แต่งงานแล้ว"
        },{
          marId: "D",
          nameTh: "อย่าร้าง"
        },{
          marId: "W",
          nameTh: "หม้าย"
        },

      ],
      salaryOptions:[
        {
          salId: "0",
          nameTh: "0 - 10,000"
        },{
          salId: "1",
          nameTh: "10,001 - 30,000"
        },{
          salId: "2",
          nameTh: "30,001 - 50,000"
        },{
          salId: "3",
          nameTh: "50,001 - 100,000"
        },{
          salId: "4",
          nameTh: "100,000 ขึ้นไป"
        },

      ],

      tooltipform: {
        residentType: "",
        maritalStatus: "",
        workPlace: "",
        houseRegistration: "",
        salary: "",
        occId: "",


        prefixIdEn:"",
        cusId: "",
        branchId: "",
        cusTypeS: [
          {
            id: "P",
            name: "บุคคล",
          },
          {
            id: "B",
            name: "นิติบุคคล",
          },
        ],
        cusType: "",
        groupId: "",
        citizenId: "",
        prefixId: "",
        nameTh: "",
        familyNameTh: "",
        nameEn: "",
        familyNameEn: "",
        birthDate: "",
        mobilePhone: "",
        email: "",
        discount: 0,
        credit: "",
        note: "",
        accountPayable: "",
        // bcreate: "",
        // creditorid: "",
        // credit: "",
      },

      addressform: {
        buildingNumber: "", //บ้านเลขที่
        townshipNumber: "", //หมู่
        townshipName: "", //ชื่อหมู่บ้าน
        subLane: "", //ตรอก
        lane: "", //ซอย
        streetName: "", //ชื่อถนน
        districtId: "", //ตำบล
        amphureId: "", //อำเภอ
        provinceId: "", //จังหวัด
        postCode: "", //รหัสไปรษณีย์
        // roomnumber: "", //เลขห้อง
        fullAddress: "", // รวมที่อยู่
        provinces: [], //รับค่าตัวแปรจังหวัด
        amphures: [], //รับค่าตัวแปรอำเภอ
        districts: [], //รับค่าตำบล
        buildingName: "", //ชื่ออาคาร

        // road: "",
      },

      UpdateAddress: {
        addrIdU: "",
        buildingNumberU: "", //บ้านเลขที่
        townshipNumberU: "", //หมู่
        townshipNameU: "", //ชื่อหมู่บ้าน
        subLaneU: "", //ตรอก
        laneU: "", //ซอย
        streetNameU: "", //ชื่อถนน
        districtIdU: "", //ตำบล
        amphurIdU: "", //อำเภอs
        provinceIdU: "", //จังหวัด
        postCodeU: "", //รหัสไปรษณีย์
        // roomnumberU: "", //เลขห้องฝ
        fullAddressU: "", // รวมที่อยู่
        provinces: [], //รับค่าตัวแปรจังหวัด
        amphures: [], //รับค่าตัวแปรอำเภอ
        districts: [], //รับค่าตำบล
        buildingNameU: "", //ชื่ออาคาร
      },
    };
  },
  validations: {
    tooltipform: {
      branchId: {
        required,
      },
      bcreate: {},
      cus_id: {},
      prefixId: {},
      cusType: {
        required,
      },
      nameTh: {
        required,
        maxLength: maxLength(50),
      },
      familyNameTh: {
        maxLength: maxLength(50),
      },
      nameEn: {
        maxLength: maxLength(50),
      },
      familyNameEn: {
        maxLength: maxLength(50),
      },
      citizenId: {
        required,
        minLength: minLength(13),
        maxLength: maxLength(13),
      },
      mobilePhone: {
        maxLength: maxLength(10),
        minLength: minLength(10),
        numeric
      },
      email: {
        email,
        maxLength: maxLength(255),
      },
      groupId: {},
      creditorid: {},
      note: {
        maxLength: maxLength(500),
      },
      discount: {
        numeric,
        maxValue: maxValue(100),
      },
      credit: {
        numeric,
        maxLength: maxLength(5),
      },
      birthDate: {},
      prefixIdEn:{},

      residentType: {  },
      maritalStatus: {  },
      workPlace: {  },
      houseRegistration: {  },
      salary: {  },
      occId: {  },
    },


    addressform: {
      buildingNumber: {
        required,
      },
      townshipNumber: {
        minLength: minValue(1),
        maxLength: maxValue(10),
      },
      // buildingName: {},
      subLane: {
        maxLength: maxLength(50),
      },
      townshipName: {
        maxLength: maxLength(50),
      },
      lane: {
        maxLength: maxLength(50),
      },
      streetName: {
        maxLength: maxLength(50),
      },
      provinceId: {
        required,
      },
      amphureId: {
        required,
      },
      districtId: {
        required,
      },
      postCode: { maxLength: maxLength(5) },
    },

    UpdateAddress: {
      buildingNumberU: {
        required,
      },
      townshipNumberU: {
        minValue: minValue(1),
        maxValue: maxValue(10),
      },
      townshipNameU: {
        maxLength: maxLength(50),
      },
      subLaneU: {
        maxLength: maxLength(50),
      },
      laneU: {
        maxLength: maxLength(50),
      },
      streetNameU: {
        maxLength: maxLength(50),
      },
      // roomnumberU: {
      //   minValue: minValue(1),
      //   maxValue: maxValue(10),
      // },
      districtIdU: {
        required,
      },
      amphurIdU: {
        required,
      },
      provinceIdU: {
        required,
      },
      postCodeU: {},
    },
  },

  methods: {
    filterResident(id){
      let result;
      this.residentTypeOptions.forEach(item=>{
        if(item.resId == id){
          
          result = item.nameTh
          // // console.log(result);
      }})
      return result     
    },
    filterMarid(id){
      let result;
      this.maritalStatusOptions.forEach(item=>{
        if(item.marId == id){
          result = item.nameTh
          // // console.log(result);
      }})
      return result     
    },
    filterSalary(id){
      let resultx;
      this.salaryOptions.forEach(item=>{
        if(item.salId == id){
          resultx = item.nameTh
          // console.log(resultx);
      }})
      return resultx    
    },
    handlePageChange(active) {
      this.perPage = active;
      this.currentPage = 1;
      this.getAddress();
      this.startIndex = (this.currentPage - 1) * this.perPage + 1;
      this.endIndex = (this.currentPage - 1) * this.perPage + this.perPage;

      if (this.endIndex > this.totalRecord) {
        this.endIndex = this.totalRecord;
      } else {
        return this.endIndex;
      }

      this.paginatedData = this.rowAddress.slice(
        this.startIndex,
        this.endIndex
      );
    },
    handleChangePage(page) {
      this.currentPage = page;
      this.getAddress();
      this.startIndex = (this.currentPage - 1) * this.perPage + 1;
      this.endIndex = (this.currentPage - 1) * this.perPage + this.perPage;
      if (this.endIndex > this.totalRecord) {
        this.endIndex = this.totalRecord;
      } else {
        return this.endIndex;
      }
      this.paginatedData = this.rowAddress.slice(
        this.startIndex,
        this.endIndex
      );
    },
    getOcc: function() {
      useNetw
        .get("api/customer/branch-occupation", {
          params: {
            page: 1,
            perPage: 100,
            branchId: this.branchItem
           }
          })
        .then((response) => {
          this.occuOptions = response.data.data;
          // // console.log(this.occuOptions);
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
        });
    },
    returnbranchName(branchId){
      // // console.log(branchId);
      let branchName = '';
      this.localData
        .filter((item) => item.branchId == branchId)
        .forEach((item) => {
          branchName = item.nameTh;
        });
        return branchName;
    },
    getpFNameTh(PFid){
      if(PFid === '0001'){
        return 'นาย'
      }else if(PFid  === '0002'){
        return 'นาง'
      }else{
        return '-'
      }
    },
    ChangeNameTh(nameTh){

      if(nameTh.nameTh  === 'นาย'){
        this.tooltipform.prefixIdEn = 'Mr.'

      }
      else if(nameTh.nameTh === 'นาง'){

        this.tooltipform.prefixIdEn = 'Mrs.'
      }
      else{
        this.tooltipform.prefixIdEn = 'Miss.'
      }
    },
    getLocalData(){


      
      const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
      this.localData = user.userAccessBranch;
      this.branchItem = user.branchId;

      const localDataBranch = [];
      const localDataBranchId = [];
      // const localBranchIdItem ={};
      this.localData.forEach((item) => {
        localDataBranch.push(item), localDataBranchId.push(item.branchId);
      });
      this.localDataBranch = localDataBranch;
      this.localDataBranchId = localDataBranchId;
    

    },



    tooltipForm() {

      this.$v.$touch();


      if (this.$v.tooltipform.$invalid) {

        // // // console.log("false");
        this.submitform = true;

      } else {
        // do your submit logic here
        this.submitUpdateCustomer();
      }
    },
    addressForm() {

      this.$v.$touch();
      if (this.$v.addressform.$invalid) {
        // // // console.log("false");
        this.submitformAddress = true;

      } else {
        // do your submit logic here
        this.postAddress();
      }
    },
    UpdateAddressU() {
      this.$v.$touch();
      // // // console.log(this.$v.UpdateAddress);
      if (this.$v.UpdateAddress.$invalid) {
        // // console.log("false");
        this.submitUpdateAddress = true;

      } else {
        // do your submit logic here
        this.putAddress();
      }
    },

    getProvinces: function() {
      this.loading = true; //skeleton true
      useNetw
        .get("api/master/address/provinces", {})
        .then((response) => {
          this.addressform.provinces = response.data.data;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
    getAmphures: function(isReset) {
      if (this.addressform.provinceId != null) {
        //skeleton true
        useNetw
          .get("api/master/address/amphures", {
            params: {
              provinceId: this.addressform.provinceId.provinceId,
            },
          })
          .then((response) => {
            this.addressform.amphures = response.data.data;
            // 0// // console.log(this.addressform.provinceId.provinceId);

            if (isReset) {
              this.addressform.amphureId = null;
              this.addressform.districtId = null;
            }
          })
          .catch((err) => {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.message),
              appConfig.swal.type.error
            );
          })
          .then(() => {
            // this.overlayFlag = false;
            //skeleton false
          });
      }
    },
    getDistricts: function(isReset) {
      if (this.addressform.provinceId != null) {
        useNetw
          .get("api/master/address/districts", {
            params: {
              amphurId: this.addressform.amphureId.amphureId,
            },
          })
          .then((response) => {
            this.addressform.districts = response.data.data;
            if (isReset) {
              this.addressform.districtId = null;
            }
          })
          .catch((err) => {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.message),
              appConfig.swal.type.error
            );
          })
          .then(() => {
            // this.overlayFlag = false;
          });
      }
    },

    //! Update

    getProvincesU: function() {
      this.loading = true; //skeleton true
      useNetw
        .get("api/master/address/provinces", {})
        .then((response) => {
          this.UpdateAddress.provinces = response.data.data;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
          // // console.log("provinces :" + this.UpdateAddress.provinces);
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
    getAmphuresU: function(isReset) {
      if (this.UpdateAddress.provinceIdU != null) {
        //skeleton true
        useNetw
          .get("api/master/address/amphures", {
            params: {
              provinceId: this.UpdateAddress.provinceIdU.provinceId,
            },
          })
          .then((response) => {
            this.UpdateAddress.amphures = response.data.data;
            // 0// // console.log(this.addressform.provinceId.provinceId);
            // // console.log(this.UpdateAddress.amphures);
            if (isReset) {
              this.UpdateAddress.amphurIdU = null;
              this.UpdateAddress.districtIdU = null;
            }
          })
          .catch((err) => {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.message),
              appConfig.swal.type.error
            );
          })
          .then(() => {
            // this.overlayFlag = false;
            //skeleton false
          });
      }
    },
    getDistrictsU: function(isReset) {
      if (this.UpdateAddress.provinceIdU != null) {
        useNetw
          .get("api/master/address/districts", {
            params: {
              amphurId: this.UpdateAddress.amphurIdU.amphureId,
            },
          })
          .then((response) => {
            this.UpdateAddress.districts = response.data.data;
            if (isReset) {
              this.UpdateAddress.districtIdU = null;
            }
          })
          .catch((err) => {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.message),
              appConfig.swal.type.error
            );
            // // console.log(this.amphurId);
          })
          .then(() => {
            // this.overlayFlag = false;
          });
      }
    },

    //! Ends Update

    getPrefixes: function() {
      // this.overlayFlag = true;

      useNetw
        .get("api/master/person/prefixes", {})
        .then((response) => {
          this.prefixOPtion = response.data.data;
          //con
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
        });
    },
    getDataUpdate: function() {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/customer/show", {
          params: {
            cusId: this.$route.params.cusId,
          },
        })
        .then((response) => {
          this.rowUser = response.data.data;
          // console.log("central", this.rowUser)
          this.tooltipform.branchId = {
            branchId: response.data.data.branchId,
            nameTh: this.returnbranchName(response.data.data.branchId),
          };
          // const custype = response.data.data.cusType
          if(response.data.data.cusType == 'B') {
            this.tooltipform.cusType = {
              id: response.data.data.cusType,
              name: "นิติบุคคล",
            };
          }else{
            this.tooltipform.cusType = {
              id: response.data.data.cusType,
              name: "บุคคล",
            };
          }


          this.tooltipform.groupId = response.data.data.groupId;
          this.tooltipform.citizenId = response.data.data.citizenId;
          (this.tooltipform.prefixId = {
            prefixId: response.data.data.prefixId,
            nameTh: this.getpFNameTh(response.data.data.prefixId),
          }),
            (this.tooltipform.nameTh = response.data.data.nameTh);
          this.tooltipform.familyNameTh = response.data.data.familyNameTh;
          this.tooltipform.nameEn = response.data.data.nameEn;
          this.tooltipform.email = response.data.data.email;
          this.tooltipform.familyNameEn = response.data.data.familyNameEn;
          this.tooltipform.birthDate = response.data.data.birthDate;
          this.tooltipform.mobilePhone = response.data.data.mobilePhone;
          this.tooltipform.discount = Number.parseFloat(
            response.data.data.discount
          ).toFixed(0);
          this.tooltipform.credit = response.data.data.credit;
          this.tooltipform.note = response.data.data.note;
          this.tooltipform.accountPayable = response.data.data.accountPayable;
          this.tooltipform.residentType = {
            resId: response.data.data.residentType,
            nameTh: this.filterResident(response.data.data.residentType)
          }
          this.tooltipform.maritalStatus = {
            marId: response.data.data.maritalStatus,
            nameTh: this.filterMarid(response.data.data.maritalStatus)
          }
          this.tooltipform.workPlace = response.data.data.workplace;
          this.tooltipform.houseRegistration = response.data.data.houseRegistration;
          this.tooltipform.salary = {
            salId : response.data.data.salary,
            nameTh: this.filterSalary(response.data.data.salary)
          }
          this.occId = {
            occId : response.data.data.occId
          }





          this.ChangeNameTh(this.tooltipform.prefixId)
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },

    getAddress: function() {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/customer/addresses", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            branchId: this.tooltipform.branchId.branchId,
            cusId: this.$route.params.cusId,
          },
        })
        .then((response) => {
          this.rowAddress = response.data.data;
          this.perPage = response.data.perPage;
          this.from = response.data.from;
          this.totalRecord = response.data.total;
          this.to = response.data.to;
          // // console.log(response.data.data);
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },

    clearAddressPost() {
      this.addressform.buildingNumber = "";
      this.addressform.townshipNumber = "";
      this.addressform.townshipName = "";
      this.addressform.subLane = "";
      this.addressform.lane = "";
      this.addressform.streetName = "";
      this.addressform.districtId = "";
      this.addressform.amphurId = "";
      this.addressform.provinceId = "";
      this.addressform.postCode = "";
      this.addressform.fullAddress = "";
      this.submitformAddress = false;
    },
    postAddress: function() {
      this.overlayFlag = true;
      const fulladdress = `${this.addressform.buildingNumber} , หมู่บ้าน ${this.addressform.townshipName}, ${this.addressform.districtId.nameTh}, ${this.addressform.amphureId.nameTh}, ${this.addressform.provinceId.nameTh}, ${this.addressform.postCode}`
      const AddressPost = new FormData();

      AddressPost.append("cusId", this.$route.params.cusId);
      AddressPost.append("buildingNumber", this.addressform.buildingNumber);
      AddressPost.append("townshipNumber", this.addressform.townshipNumber);
      AddressPost.append("townshipName", this.addressform.townshipName);
      AddressPost.append("subLane", this.addressform.subLane);
      AddressPost.append("lane", this.addressform.lane);
      AddressPost.append("streetName", this.addressform.streetName);
      AddressPost.append("districtId", this.addressform.districtId.districtId);
      AddressPost.append("amphurId", this.addressform.amphureId.amphureId);
      AddressPost.append("provinceId", this.addressform.provinceId.provinceId);
      AddressPost.append("postCode", this.addressform.postCode);
      AddressPost.append("fullAddress", fulladdress);
      // AddressPost.append(
      //   "prefixId",
      //   this.tooltipform.prefixId.prefixId != undefined
      //     ?this.tooltipform.prefixId.prefixId
      //     : ""
      // );
      useNetw
        .post("api/customer/address/store", AddressPost)
        .then((response) => {
          // this.getUser();
          //con
          this.$refs["modal-1"].hide();
          Swal.fire(
            appConfig.swal.title.getSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );

          this.getAddress();
          this.clearAddressPost();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.message +
                "<br>" +
                err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },

    // editAddress: function () {},

    submitUpdateCustomer: function() {
      useNetw
        .put("/api/customer/update", {
          cusId: this.$route.params.cusId,
          branchId: this.tooltipform.branchId.branchId,

          cusType:
            this.tooltipform.cusType.id != null
              ? this.tooltipform.cusType.id
              : this.tooltipform.cusType,
          groupId: "",
          prefixId:
            this.tooltipform.prefixId.prefixId != null
              ? this.tooltipform.prefixId.prefixId
              : this.tooltipform.prefixId.prefixId,
          nameTh: this.tooltipform.nameTh,
          familyNameTh:
            this.tooltipform.familyNameTh != null
              ? this.tooltipform.familyNameTh
              : "",
          nameEn:
            this.tooltipform.nameEn != null ? this.tooltipform.nameEn : "",
          familyNameEn:
            this.tooltipform.familyNameEn != null
              ? this.tooltipform.familyNameEn
              : "",
          birthDate:
            this.tooltipform.birthDate != null
              ? this.tooltipform.birthDate
              : "",
          mobilePhone:
            this.tooltipform.mobilePhone != null
              ? this.tooltipform.mobilePhone
              : "",
          discount:
            this.tooltipform.discount != null ? this.tooltipform.discount : "",
          credit:
            this.tooltipform.credit != null ? this.tooltipform.credit : "",
          accountPayable: "",
          note: this.tooltipform.note != null ? this.tooltipform.note : "",
          email:
            this.tooltipform.email != null
              ? this.tooltipform.email
              : this.email,
          residentType: this.tooltipform.residentType.resId,
          maritalStatus: this.tooltipform.maritalStatus.marId,
          workPlace: this.tooltipform.workPlace,
          houseRegistration: this.tooltipform.houseRegistration,
          salary: this.tooltipform.salary.salId,
          occId: parseInt(this.tooltipform.occId.occId)
    
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.getSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {});
    },


    ConfirmdeleteAddress(ArrId){
      Swal.fire({
        title: "ต้องการลบที่อยู่นี้ใช่หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "ลบ",
        cancelButtonText: "ยกเลิก",
      }).then((result) => {
        if (result.value) {
          this.deleteAddress(ArrId);
        }
      });
    },
    deleteAddress: function(rowAddress) {
      this.rowAddress = rowAddress;
      this.loading = true; //skeleton true
      useNetw
        .delete("api/customer/address/delete", {
          data: {
            addrId: this.rowAddress,
          },
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.getSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.getAddress();
        })

        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },

    // showModalUpdateAddress() {
    // this.getAddressUpdate(rowAddress);
    //   this.$refs["modalUpdateAddress"].show();
    // },

    getAddressUpdate: function(rowAddress) {
      this.rowAddress = rowAddress;
      this.loading = true; //skeleton true
      useNetw
        .get("api/customer/address/show", {
          params: {
            addrId: this.rowAddress,
          },
        })
        .then((response) => {
          console.log(response.data.data);
          this.modalUpdateAddress = true;
          //con
          this.UpdateAddress.addrIdU = response.data.data.addrId;
          this.UpdateAddress.buildingNumberU =
            response.data.data.buildingNumber;
          this.UpdateAddress.townshipNumberU =
            response.data.data.townshipNumber;
          this.UpdateAddress.townshipNameU = response.data.data.townshipName;
          this.UpdateAddress.laneU = response.data.data.lane;
          this.UpdateAddress.streetNameU = response.data.data.streetName;
          this.UpdateAddress.provinceIdU = {
            provinceId: response.data.data.provinceId,
            nameTh: response.data.data.provinceNameTh,
          };
          this.UpdateAddress.amphurIdU = {
            amphureId: response.data.data.amphureId,
            nameTh: response.data.data.amphurNameTh,
          };
          this.UpdateAddress.districtIdU = {
            districtId: response.data.data.districtId,
            nameTh: response.data.data.districtNameTh,
          };
          this.UpdateAddress.postCodeU = response.data.data.postCode;

          // // console.log(response.data.data);
          // // console.log("addrId is :" + this.UpdateAddress.addrIdU);

          this.getProvincesU();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },

    putAddress: function() {
      this.loading = true;
      const fulladdress = `${!this.UpdateAddress.buildingNumberU ? '' : this.UpdateAddress.buildingNumberU} , หมู่บ้าน : ${!this.UpdateAddress.townshipNameU ? '' : this.UpdateAddress.townshipNameU}, ${this.UpdateAddress.districtIdU.nameTh}, ${this.UpdateAddress.amphurIdU.nameTh}, ${this.UpdateAddress.provinceIdU.nameTh}, ${this.UpdateAddress.postCodeU}`
      useNetw
        .put("api/customer/address/update", {
          addrId: this.UpdateAddress.addrIdU,
          buildingNumber: this.UpdateAddress.buildingNumberU,
          townshipNumber: this.UpdateAddress.townshipNumberU,
          townshipName: this.UpdateAddress.townshipNameU,
          subLane: this.UpdateAddress.subLaneU,
          lane: this.UpdateAddress.laneU,
          streetName: this.UpdateAddress.streetNameU,
          districtId: this.UpdateAddress.districtIdU.districtId,
          amphurId: this.UpdateAddress.amphurIdU.amphureId,
          provinceId: this.UpdateAddress.provinceIdU.provinceId,
          postCode: this.UpdateAddress.postCodeU,
          fullAddress: fulladdress,
          // branchId: 1,
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.getSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.loading = false;
          this.getAddress();
          this.modalUpdateAddress = false;
        });
    },

    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.rowAddress = filteredItems.length;
      this.currentPage = 1;
    },

    onRowSelected(rowData) {
      this.selected = rowData;
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },
  },
};
</script>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-lg-12">
        <b-skeleton-wrapper :loading="loading"
          ><!--// !skeleton start-->
          <template #loading>
            <b-card>
              <b-skeleton animation="wave" width="10%"></b-skeleton>
              <b-skeleton animation="wave" width="15%"></b-skeleton><br />
              <h1>
                <b-skeleton type="input" animation="throb"></b-skeleton>
              </h1>
              <br />
              <div class="row">
                <div class="col-md-4">
                  <b-skeleton type="input" animation="throb"></b-skeleton>
                </div>
                <div class="col-md-6">
                  <b-skeleton type="input" animation="throb"></b-skeleton>
                </div>
                <div class="col-md-2">
                  <b-skeleton type="input" animation="throb"></b-skeleton>
                </div>
              </div>
              <br />
              <b-skeleton-table
                :rows="5"
                :columns="2"
                :table-props="{ bordered: false, striped: true }"
              ></b-skeleton-table>
            </b-card>
          </template>
          <!--// !skeleton ends-->

          <div class="card">
            <form class="needs-validation" @submit.prevent="tooltipForm">
              <div class="card-body">
                <div class="text-end">
                  <router-link :to="{ name: 'maincus' }">
                    <button class="btn btn-secondary">
                      กลับสู่หน้าหลัก
                    </button>
                  </router-link>
                </div>
                <hr />
                <h4 class="card-title">ข้อมูลลูกค้า</h4>
                <h4 class="card-title">ส่วนที่ 1 ข้อมูลทั่วไป</h4>

                <div class="row">
                  <div class="col-md-4">
                    <div class="mb-3 position-relative">
                      <label for="branchId">สาขาที่สร้างรหัสลูกค้า :</label
                      ><br />
                      <multiselect
                        v-model="tooltipform.branchId"
                        :options="localData"
                        label="nameTh"
                        :class="{
                          'is-invalid':
                            submitform && $v.tooltipform.branchId.$error,
                        }"
                      >
                      </multiselect>
                      <div
                        v-if="submitform && $v.tooltipform.branchId.$error"
                        class="invalid-tooltip"
                      >
                        <span v-if="!$v.tooltipform.branchId.required">{{
                          error
                        }}</span>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-2">
                    <div class="mb-3 position-relative">
                      <code>* </code><label for="cusType">ประเภทบุคคล :</label>
                      <multiselect
                        v-model="tooltipform.cusType"
                        :options="tooltipform.cusTypeS"
                        label="name"
                        :class="{
                          'is-invalid':
                            submitform && $v.tooltipform.cusType.$error,
                        }"
                      >
                      </multiselect>
                      <div
                        v-if="submitform && $v.tooltipform.cusType.$error"
                        class="invalid-tooltip"
                      >
                        <span v-if="!$v.tooltipform.cusType.required">{{
                          error
                        }}</span>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-4">
                    <div class="mb-3 position-relative">
                      <label for="validationTooltipid">รหัสลูกค้า :</label
                      ><br />
                      <input
                        v-model="tooltipform.cus_id"
                        type="text"
                        class="form-control"
                        placeholder="รหัสลูกค้า"
                        disabled
                        :class="{
                          'is-invalid':
                            submitform && $v.tooltipform.cus_id.$error,
                        }"
                      />
                      <div
                        v-if="submitform && $v.tooltipform.cus_id.$error"
                        class="invalid-tooltip"
                      >
                        <span v-if="!$v.tooltipform.cus_id.required">{{
                          error
                        }}</span>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-2">
                    <div class="mb-3 position-relative">
                      <label for="groupId">กลุ่มลูกค้า :</label>
                      <multiselect
                        v-model="tooltipform.groupId"
                        :options="options"
                        :class="{
                          'is-invalid':
                            submitform && $v.tooltipform.groupId.$error,
                        }"
                      >
                      </multiselect>
                      <div
                        v-if="submitform && $v.tooltipform.groupId.$error"
                        class="invalid-tooltip"
                      >
                        <span v-if="!$v.tooltipform.groupId.required">{{
                          error
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-4">
                    <div class="mb-3 position-relative">
                      <code>* </code
                      ><label for="citizenId">รหัสบัตรประชาชน :</label>
                      <input
                        maxlength="13"
                        v-model="tooltipform.citizenId"
                        type="text"
                        class="form-control"
                        placeholder="รหัสบัตรประชาชน 13 หลัก"
                        disabled
                        :class="{
                          'is-invalid':
                            submitform && $v.tooltipform.citizenId.$error,
                        }"
                      />
                      <div
                        v-if="submitform && $v.tooltipform.citizenId.$error"
                        class="invalid-tooltip"
                      >
                        <span
                          v-if="
                            !$v.tooltipform.citizenId.minLength ||
                              !$v.tooltipform.citizenId.maxLength ||
                              !$v.tooltipform.citizenId.required
                          "
                          >{{ error }}</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="mb-3 position-relative">
                      <label for="prefixOPtion">คำนำหน้าชื่อ :</label>
                      <multiselect
                        @input="ChangeNameTh(tooltipform.prefixId)"
                        v-model="tooltipform.prefixId"
                        :options="prefixOPtion"
                        label="nameTh"
                        :disabled="tooltipform.cusType.id === 'B'"
                        :class="{
                          'is-invalid':
                            submitform && $v.tooltipform.prefixId.$error,
                        }"
                      >
                      </multiselect>
                      <div
                        v-if="submitform && $v.tooltipform.prefixId.$error"
                        class="invalid-tooltip"
                      >
                        <span v-if="!$v.tooltipform.prefixId.required"
                          >{{ error }}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div class="mb-3 position-relative">
                      <code>* </code><label for="nameTh">ชื่อ :</label>
                      <input
                        v-model="tooltipform.nameTh"
                        type="text"
                        class="form-control"
                        placeholder="ชื่อ"
                        :class="{
                          'is-invalid':
                            submitform && $v.tooltipform.nameTh.$error,
                        }"
                      />
                      <div
                        v-if="submitform && $v.tooltipform.nameTh.$error"
                        class="invalid-tooltip"
                      >
                        <span
                          v-if="
                            !$v.tooltipform.nameTh.required ||
                              !$v.tooltipform.nameTh.maxLength
                          "
                          >{{ error }}</span
                        >
                      </div>
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div class="mb-3 position-relative">
                      <label for="validationTooltiplame">นามสกุล :</label>
                      <input
                        v-model="tooltipform.familyNameTh"
                        type="text"
                        class="form-control"
                        placeholder="นามสกุล"
                        :disabled="tooltipform.cusType.id === 'B'"
                        :class="{
                          'is-invalid':
                            submitform && $v.tooltipform.familyNameTh.$error,
                        }"
                      />
                      <div
                        v-if="submitform && $v.tooltipform.familyNameTh.$error"
                        class="invalid-tooltip"
                      >
                        <span v-if="!$v.tooltipform.familyNameTh.maxLength">{{
                          error
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-2">
                    <div class="mb-3 position-relative">
                      <label for="validationTooltipnameENG"
                        >คำนำหน้าชื่อ(อังกฤษ)</label
                      >
                      <input
                        v-model="tooltipform.prefixIdEn"
                        type="text"
                        class="form-control"
                        placeholder="prefix"
                        value=""
                      />
                      <div
                        v-if="submitform && $v.tooltipform.prefixIdEn.$error"
                        class="invalid-tooltip"
                      >
                        <span v-if="!$v.tooltipform.prefixIdEn.required">{{
                          error
                        }}</span>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div class="mb-3 position-relative">
                      <label for="nameEn">ชื่อ(อังกฤษ) :</label>
                      <input
                        v-model="tooltipform.nameEn"
                        type="text"
                        class="form-control"
                        placeholder="ชื่อ (อังกฤษ) "
                        :class="{
                          'is-invalid':
                            submitform && $v.tooltipform.nameEn.$error,
                        }"
                      />
                      <div
                        v-if="submitform && $v.tooltipform.nameEn.$error"
                        class="invalid-tooltip"
                      >
                        <span v-if="!$v.tooltipform.nameEn.maxLength">
                          {{ error }}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div class="mb-3 position-relative">
                      <label for="validationTooltipenflname"
                        >นามสกุล(อังกฤษ)</label
                      >
                      <input
                        v-model="tooltipform.familyNameEn"
                        type="text"
                        class="form-control"
                        placeholder="นามสกุล (อังกฤษ) "
                        :disabled="tooltipform.cusType.id === 'B'"
                        :class="{
                          'is-invalid':
                            submitform && $v.tooltipform.familyNameEn.$error,
                        }"
                      />
                      <div
                        v-if="submitform && $v.tooltipform.familyNameEn.$error"
                        class="invalid-tooltip"
                      >
                        <span v-if="!$v.tooltipform.familyNameEn.maxLength">{{
                          error
                        }}</span>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-2">
                    <div class="mb-3 position-relative">
                      <label for="birthDate">วันเกิด :</label>

                      <date-picker
                        v-model="tooltipform.birthDate"
                        :first-day-of-week="1"
                        format="YYYY-MM-DD"
                        value-type="format"
                        lang="en"
                        :class="{
                          'is-invalid':
                            submitform && $v.tooltipform.birthDate.$error,
                        }"
                      >
                      </date-picker>
                      <div
                        v-if="submitform && $v.tooltipform.birthDate.$error"
                        class="invalid-tooltip"
                      >
                        <span v-if="!$v.tooltipform.birthDate.required">{{
                          error
                        }}</span>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-2">
                    <div class="mb-3 position-relative">
                      <label for="mobilePhone">เบอร์โทรศัพท์ :</label>
                      <input
                        maxlength="10"
                        v-model="tooltipform.mobilePhone"
                        type="tel"
                        class="form-control"
                        placeholder="เบอร์โทรศัพท์ แบบไม่ใส่ขีด"
                        :class="{
                          'is-invalid':
                            submitform && $v.tooltipform.mobilePhone.$error,
                        }"
                      />
                      <div
                        v-if="submitform && $v.tooltipform.mobilePhone.$error"
                        class="invalid-tooltip"
                      >
                        <span v-if="!$v.tooltipform.mobilePhone.required"
                          >P{{ error }}</span
                        >
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-4">
                    <div class="mb-3 position-relative">
                      <label for="validationTooltipemail">อีเมล :</label>
                      <input
                        v-model="tooltipform.email"
                        type="email"
                        class="form-control"
                        placeholder="ตัวอย่าง : example@mail.com"
                        :class="{
                          'is-invalid':
                            submitform && $v.tooltipform.email.$error,
                        }"
                      />
                      <div
                        v-if="submitform && $v.tooltipform.email.$error"
                        class="invalid-tooltip"
                      >
                        <span v-if="!$v.tooltipform.email.required">{{
                          error
                        }}</span>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-2">
                    <div class="mb-3 position-relative">
                      <label for="validationTooltipdiscount"
                        >ส่วนลด (%) :</label
                      >
                      <input
                        v-model="tooltipform.discount"
                        type="number"
                        class="form-control"
                        :class="{
                          'is-invalid':
                            submitform && $v.tooltipform.discount.$error,
                        }"
                      />
                      <div
                        v-if="submitform && $v.tooltipform.discount.$error"
                        class="invalid-tooltip"
                      >
                        <span v-if="!$v.tooltipform.discount.required">{{
                          error
                        }}</span>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-2">
                    <div class="mb-3 position-relative">
                      <label for="validationTooltipcredit"
                        >เครดิต (วัน) :</label
                      >
                      <input
                        v-model="tooltipform.credit"
                        type="number"
                        class="form-control"
                        :class="{
                          'is-invalid':
                            submitform && $v.tooltipform.credit.$error,
                        }"
                      />
                      <div
                        v-if="submitform && $v.tooltipform.credit.$error"
                        class="invalid-tooltip"
                      >
                        <span v-if="!$v.tooltipform.credit.required">{{
                          error
                        }}</span>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-4">
                    <div class="mb-3 position-relative">
                      <label for="validationTooltipcreditorid"
                        >เจ้าหนี้ :</label
                      >
                      <multiselect
                        v-model="tooltipform.creditorid"
                        label="first_name"
                        :options="dataTest"
                        :class="{
                          'is-invalid':
                            submitform && $v.tooltipform.creditorid.$error,
                        }"
                      >
                      </multiselect>
                      <div
                        v-if="submitform && $v.tooltipform.creditorid.$error"
                        class="invalid-tooltip"
                      >
                        <span v-if="!$v.tooltipform.creditorid.required">
                          {{ error }}</span
                        >
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6">
                    <div class="mb-3 position-relative">
                      <label for="validationTooltipnote">หมายเหตุ :</label>

                      <textarea
                        v-model="tooltipform.note"
                        id="formmessage"
                        rows="3"
                        class="form-control"
                        style="
                          margin-top: 0px;
                          margin-bottom: 0px;
                          height: 115px;
                        "
                        :class="{
                          'is-invalid':
                            submitform && $v.tooltipform.note.$error,
                        }"
                      >
                      </textarea>

                      <div
                        v-if="submitform && $v.tooltipform.note.$error"
                        class="invalid-tooltip"
                      >
                        <span v-if="!$v.tooltipform.note.maxlength">{{
                          error
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-4">
                    <div class="mb-3 position-relative">
                      <label for="branchId"
                        >ประเภทที่อยู่อาศัย :</label
                      ><br />
                      <multiselect
                        v-model="tooltipform.residentType"
                        :options="residentTypeOptions"
                        label="nameTh"
                        :class="{
                          'is-invalid':
                            submitform && $v.tooltipform.residentType.$error,
                        }"
                      >
                      </multiselect>
                      <div
                        v-if="submitform && $v.tooltipform.residentType.$error"
                        class="invalid-tooltip"
                      >
                        <span v-if="!$v.tooltipform.residentType.required">{{
                          error
                        }}</span>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-4">
                    <div class="mb-3 position-relative">
                      <label for="branchId"> สถานะ :</label
                      ><br />
                      <multiselect
                        v-model="tooltipform.maritalStatus"
                        :options="maritalStatusOptions"
                        label="nameTh"
                        :class="{
                          'is-invalid':
                            submitform && $v.tooltipform.maritalStatus.$error,
                        }"
                      >
                      </multiselect>
                      <div
                        v-if="submitform && $v.tooltipform.maritalStatus.$error"
                        class="invalid-tooltip"
                      >
                        <span v-if="!$v.tooltipform.maritalStatus.required">{{
                          error
                        }}</span>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-4">
                    <div class="mb-3 position-relative">
                      <label for="branchId">รายได้ :</label
                      ><br />
                      <multiselect
                        v-model="tooltipform.salary"
                        :options="salaryOptions"
                        label="nameTh"
                        :class="{
                          'is-invalid':
                            submitform && $v.tooltipform.salary.$error,
                        }"
                      >
                      </multiselect>
                      <div
                        v-if="submitform && $v.tooltipform.salary.$error"
                        class="invalid-tooltip"
                      >
                        <span v-if="!$v.tooltipform.salary.required">{{
                          error
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-4">
                    <div class="mb-3 position-relative">
                     <label for="branchId">อาชีพ :</label><br />
                      <multiselect
                        v-model="tooltipform.occId"
                        :options="occuOptions"
                        label="nameTh"
                        :class="{
                          'is-invalid':
                            submitform && $v.tooltipform.occId.$error,
                        }"
                      >
                      </multiselect>
                      <div
                        v-if="submitform && $v.tooltipform.occId.$error"
                        class="invalid-tooltip"
                      >
                        <span v-if="!$v.tooltipform.occId.required">{{
                          error
                        }}</span>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-8">
                    <div class="mb-3 position-relative">
                      <label for="citizenId">สถานที่ทำงาน :</label>
                      <input
                        maxlength="250"
                        v-model="tooltipform.workPlace"
                        type="text"
                        class="form-control"
                        placeholder="ที่อยู่ที่ทำงาน"
                        :class="{
                          'is-invalid':
                            submitform && $v.tooltipform.workPlace.$error,
                        }"
                      />
                      <div
                        v-if="submitform && $v.tooltipform.workPlace.$error"
                        class="invalid-tooltip"
                      >
                        <span v-if="!$v.tooltipform.workPlace.required">{{
                          error
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-8">
                    <div class="mb-3 position-relative">
                      <label for="citizenId">เลขที่เล่มทะเบียนบ้าน :</label>
                      <input
                        maxlength="250"
                        v-model="tooltipform.houseRegistration"
                        type="text"
                        class="form-control"
                        placeholder="เลขที่เล่มทะเบียนบ้าน"
                        :class="{
                          'is-invalid':
                            submitform &&
                            $v.tooltipform.houseRegistration.$error,
                        }"
                      />
                      <div
                        v-if="
                          submitform && $v.tooltipform.houseRegistration.$error
                        "
                        class="invalid-tooltip"
                      >
                        <span
                          v-if="!$v.tooltipform.houseRegistration.required"
                          >{{ error }}</span
                        >
                      </div>
                    </div>
                  </div>
                </div>

                <div class="text-end">
                  <button type="submit" class="btn btn-primary">
                    บันทึก
                  </button>
                </div>
              </div>
            </form>
            <div class="row">
              <div class="col-md-12 text-end">
                <button
                  class="popup-form btn btn-success"
                  href="javascript: void(0);"
                  v-b-modal.modal-1
                >
                  เพิ่มที่อยู่
                </button>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </div>
            </div>
            <hr />
          </div>
          <!-- //!end card -->

          <div class="card">
            <div class="card-body">
              <h4 class="card-title">ส่วนที่ 2 ข้อมูลที่อยู่</h4>
              <br />
              <!-- //*เริ่มส่วน 2 ตรงนี้ -->

              <b-modal
                id="modal-1"
                title="เพิ่มที่อยู่"
                hide-footer
                ref="modal-1"
                size="lg"
                centered
              >
                <div class="card-body">
                  <form class="needs-validation" @submit.prevent="addressForm">
                    <div class="row">
                      <div class="col-lg-3">
                        <div class="mb-3 position-relative">
                          <code>* </code
                          ><label for="validation">บ้านเลขที่ :</label>
                          <input
                            v-model="addressform.buildingNumber"
                            type="text"
                            class="form-control"
                            placeholder="บ้านเลขที่"
                            :class="{
                              'is-invalid':
                                submitformAddress &&
                                $v.addressform.buildingNumber.$error,
                            }"
                          />
                          <div
                            v-if="
                              submitformAddress &&
                                $v.addressform.buildingNumber.$error
                            "
                            class="invalid-tooltip"
                          >
                            <span
                              v-if="!$v.addressform.buildingNumber.required"
                              >{{ error }}</span
                            >
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-3">
                        <div class="mb-3 position-relative">
                          <label for="validation">หมู่ที่ :</label>
                          <input
                            v-model="addressform.townshipNumber"
                            type="text"
                            class="form-control"
                            placeholder="หมู่ที่"
                            :class="{
                              'is-invalid':
                                submitformAddress &&
                                $v.addressform.townshipNumber.$error,
                            }"
                          />
                          <div
                            v-if="
                              submitformAddress &&
                                $v.addressform.townshipNumber.$error
                            "
                            class="invalid-tooltip"
                          >
                            <span
                              v-if="!$v.addressform.townshipNumber.maxValue"
                              ||
                              !v-if="!$v.addressform.townshipNumber.minValue"
                              >{{ error }}</span
                            >
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-6">
                        <div class="mb-3 position-relative">
                          <label for="validation">ตรอก :</label>
                          <input
                            v-model="addressform.subLane"
                            type="text"
                            class="form-control"
                            placeholder="ตรอก"
                            :class="{
                              'is-invalid':
                                submitformAddress &&
                                $v.addressform.subLane.$error,
                            }"
                          />
                          <div
                            v-if="
                              submitformAddress && $v.addressform.subLane.$error
                            "
                            class="invalid-tooltip"
                          >
                            <span v-if="!$v.addressform.subLane.maxLength">{{
                              error
                            }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- end rows 1 -->

                    <div class="row">
                      <!-- <div class="col-lg-2">
                        <div class="mb-3 position-relative">
                          <code>* </code
                          ><label for="validation">หมายเลขห้อง :</label>
                          <input
                            v-model="addressform.roomnumber"
                            type="text"
                            class="form-control"
                            placeholder="เลขห้อง"
                            :class="{
                              'is-invalid':
                                submitformAddress &&
                                $v.addressform.roomnumber.$error,
                            }"
                          />
                          <div
                            v-if="
                              submitformAddress &&
                              $v.addressform.roomnumber.$error
                            "
                            class="invalid-tooltip"
                          >
                            <span
                              v-if="
                                !$v.addressform.roomnumber.minValue ||
                                !$v.addressform.roomnumber.maxValue
                              "
                              >{{ error }}</span
                            >
                          </div>
                        </div>
                      </div> -->

                      <div class="col-lg-5">
                        <div class="mb-3 position-relative">
                          <label for="validation">ชื่อหมู่บ้าน :</label>
                          <input
                            v-model="addressform.townshipName"
                            type="text"
                            class="form-control"
                            placeholder="ชื่อหมู่บ้าน"
                            :class="{
                              'is-invalid':
                                submitformAddress &&
                                $v.addressform.townshipName.$error,
                            }"
                          />
                          <div
                            v-if="
                              submitformAddress &&
                                $v.addressform.townshipName.$error
                            "
                            class="invalid-tooltip"
                          >
                            <span
                              v-if="!$v.addressform.townshipName.maxLength"
                              >{{ error }}</span
                            >
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-3">
                        <div class="mb-3 position-relative">
                          <label for="validation">ซอย :</label>
                          <input
                            v-model="addressform.lane"
                            type="text"
                            class="form-control"
                            placeholder="ซอย"
                            :class="{
                              'is-invalid':
                                submitformAddress && $v.addressform.lane.$error,
                            }"
                          />
                          <div
                            v-if="
                              submitformAddress && $v.addressform.lane.$error
                            "
                            class="invalid-tooltip"
                          >
                            <span v-if="!$v.addressform.lane.maxlength">{{
                              error
                            }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!--End rows 2 -->

                    <div class="row">
                      <div class="col-lg-4">
                        <div class="mb-3 position-relative">
                          <label for="validation">ถนน :</label>
                          <input
                            v-model="addressform.streetName"
                            type="text"
                            class="form-control"
                            placeholder="ชื่อถนน"
                            :class="{
                              'is-invalid':
                                submitformAddress &&
                                $v.addressform.streetName.$error,
                            }"
                          />
                          <div
                            v-if="
                              submitformAddress &&
                                $v.addressform.streetName.$error
                            "
                            class="invalid-tooltip"
                          >
                            <span v-if="!$v.addressform.streetName.maxlength">{{
                              error
                            }}</span>
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-4">
                        <div class="mb-3 position-relative">
                          <code>* </code
                          ><label for="provinceId">จังหวัด :</label>
                          <multiselect
                            v-model="addressform.provinceId"
                            track-by="nameTh"
                            @input="getAmphures"
                            :options="addressform.provinces"
                            label="nameTh"
                            :class="{
                              'is-invalid':
                                submitformAddress &&
                                $v.addressform.provinceId.$error,
                            }"
                          >
                            <!-- <template slot="singleLabel" slot-scope="{ option }"
                              ><strong>{{ option.nameTh }}</strong></template
                            > -->
                          </multiselect>
                          <div
                            v-if="
                              submitformAddress &&
                                $v.addressform.provinceId.$error
                            "
                            class="invalid-tooltip"
                          >
                            <span v-if="!$v.addressform.provinceId.required">
                              {{ error }}</span
                            >
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-4">
                        <div class="mb-3 position-relative">
                          <code>* </code><label for="amphureId">อำเภอ :</label>
                          <multiselect
                            v-model="addressform.amphureId"
                            track-by="nameTh"
                            :options="addressform.amphures"
                            @input="getDistricts"
                            label="nameTh"
                            :class="{
                              'is-invalid':
                                submitformAddress &&
                                $v.addressform.amphureId.$error,
                            }"
                          >
                          </multiselect>
                          <div
                            v-if="
                              submitformAddress &&
                                $v.addressform.amphureId.$error
                            "
                            class="invalid-tooltip"
                          >
                            <span v-if="!$v.addressform.amphureId.required">
                              {{ error }}</span
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- End Rows-->

                    <div class="row">
                      <div class="col-lg-4">
                        <div class="mb-3 position-relative">
                          <code>* </code><label for="districtId">ตำบล :</label>
                          <multiselect
                            v-model="addressform.districtId"
                            track-by="nameTh"
                            :options="addressform.districts"
                            label="nameTh"
                            :class="{
                              'is-invalid':
                                submitformAddress &&
                                $v.addressform.districtId.$error,
                            }"
                          >
                          </multiselect>
                          <div
                            v-if="
                              submitformAddress &&
                                $v.addressform.districtId.$error
                            "
                            class="invalid-tooltip"
                          >
                            <span v-if="!$v.addressform.districtId.required">
                              {{ error }}</span
                            >
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-5">
                        <div class="mb-3 position-relative">
                          <label for="validation">รหัสไปรษณีย์ :</label>
                          <input
                            maxlength="5"
                            v-model="addressform.postCode"
                            type="text"
                            class="form-control"
                            placeholder="รหัสไปรษณีย์"
                            :class="{
                              'is-invalid':
                                submitformAddress &&
                                $v.addressform.postCode.$error,
                            }"
                          />
                          <div
                            v-if="
                              submitformAddress &&
                                $v.addressform.postCode.$error
                            "
                            class="invalid-tooltip"
                          >
                            <span v-if="!$v.addressform.postCode.maxLength">{{
                              error
                            }}</span>
                          </div>
                        </div>
                      </div>
                      {{ addressform.fullAddress }}
                    </div>

                    <div class="text-end">
                      <button type="submit" class="btn btn-primary">
                        บันทึก
                      </button>
                    </div>
                  </form>
                </div>
              </b-modal>

              <div class="row">
                <div class="col-sm-12 col-md-6">
                  <div id="tickets-table_length" class="dataTables_length">
                    <label class="d-inline-flex align-items-center">
                      แสดงผล&nbsp;
                      <b-form-select
                        v-model="perPage"
                        size="sm"
                        :options="pageOptions"
                        @input="handlePageChange"
                      ></b-form-select
                      >&nbsp;รายการ :</label
                    >
                  </div>
                </div>
                <!-- Search -->
                <div class="col-sm-12 col-md-6">
                  <div
                    id="tickets-table_filter"
                    class="dataTables_filter text-md-end"
                  >
                    <label class="d-inline-flex align-items-center">
                      Search:
                      <b-form-input
                        v-model="filter"
                        type="search"
                        placeholder="Search..."
                        class="form-control form-control-sm ms-2"
                      ></b-form-input>
                      :</label
                    >
                  </div>
                </div>
                <!-- End search -->
                <div class="col-md-12">
                  <div class="table-responsive mb-0">
                    <b-table
                      v-if="rowAddress && rowAddress.length > 0 && !loading"
                      id="table-transition-userList"
                      :key="rowAddress.addrId"
                      :items="rowAddress"
                      :fields="fields"
                      responsive="sm"
                      :per-page="perPage"
                      :current-page="1"
                      :sort-by.sync="sortBy"
                      :sort-desc.sync="sortDesc"
                      :filter="filter"
                      :filter-included-fields="filterOn"
                      :select-mode="selectMode"
                      ref="selectableTable"
                      @row-selected="onRowSelected"
                    >
                      <template #cell(selected)="{ rowSelected }">
                        <template v-if="rowSelected">
                          <span aria-hidden="true">&check;</span>
                          <span class="sr-only">Selected</span>
                        </template>
                        <template v-else>
                          <span aria-hidden="true">&nbsp;</span>
                          <span class="sr-only">Not selected</span>
                        </template>
                      </template>

                      <!-- edit table -->
                      <template v-slot:cell(action)="rowAddress">
                        <a
                          class="px-2 text-primary"
                          v-b-tooltip.hover
                          title="Edit"
                          @click="getAddressUpdate(rowAddress.item.addrId)"
                        >
                          <i class="uil uil-pen font-size-18"></i>
                        </a>
                        <a
                          class="px-2 text-danger"
                          v-b-tooltip.hover
                          title="Delete"
                          @click="ConfirmdeleteAddress(rowAddress.item.addrId)"
                        >
                          <i class="uil uil-trash-alt font-size-18"></i>
                        </a>
                      </template>

                      <!-- End edit table -->
                    </b-table>
                  </div>
                  <div class="row">
                    <div class="col">
                      แสดงผล {{ startIndex }} to {{ endIndex }} of
                      {{ totalRecord }} รายการ
                    </div>

                    <div class="col">
                      <div
                        class="
                          dataTables_paginate
                          paging_simple_numbers
                          float-end
                        "
                      >
                        <ul class="pagination pagination-rounded mb-0">
                          <!-- pagination -->
                          <b-pagination
                            v-model="currentPage"
                            :total-rows="totalRecord"
                            :per-page="perPage"
                            @change="handleChangePage"
                          ></b-pagination>
                        </ul>
                      </div>
                    </div>
                    <br />
                    <hr />
                    <br />
                    <!-- <p>
                      <b-button size="sm" @click="selectAllRows"
                        >Select all</b-button
                      >
                      |
                      <b-button size="sm" @click="clearSelected"
                        >Clear selected</b-button
                      >
                    </p> -->
                    <!-- <p>
                      Selected Rows:<br />
                      {{ selected }}
                    </p> -->
                  </div>
                </div>
              </div>
            </div>
            <!-- end card -->

            <b-modal
              v-model="modalUpdateAddress"
              title="แก้ไขที่อยู่"
              hide-footer
              size="lg"
              centered
            >
              <div class="card-body">
                <form class="needs-validation" @submit.prevent="UpdateAddressU">
                  <div class="row">
                    <div class="col-lg-3">
                      <div class="mb-3 position-relative">
                        <code>* </code
                        ><label for="validation">บ้านเลขที่ :</label>
                        <input
                          v-model="UpdateAddress.buildingNumberU"
                          type="text"
                          class="form-control"
                          placeholder="บ้านเลขที่"
                          :class="{
                            'is-invalid':
                              submitUpdateAddress &&
                              $v.UpdateAddress.buildingNumberU.$error,
                          }"
                        />
                        <div
                          v-if="
                            submitUpdateAddress &&
                              $v.UpdateAddress.buildingNumberU.$error
                          "
                          class="invalid-tooltip"
                        >
                          <span
                            v-if="!$v.UpdateAddress.buildingNumberU.required"
                            >{{ error }}</span
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-3">
                      <div class="mb-3 position-relative">
                        <label for="validation">หมู่ที่ :</label>
                        <input
                          v-model="UpdateAddress.townshipNumberU"
                          type="text"
                          class="form-control"
                          placeholder="หมู่ที่"
                          :class="{
                            'is-invalid':
                              submitUpdateAddress &&
                              $v.UpdateAddress.townshipNumberU.$error,
                          }"
                        />
                        <div
                          v-if="
                            submitUpdateAddress &&
                              $v.UpdateAddress.townshipNumberU.$error
                          "
                          class="invalid-tooltip"
                        >
                          <span
                            v-if="
                              !$v.UpdateAddress.townshipNumberU.minValue ||
                                !$v.UpdateAddress.townshipNumberU.maxValue
                            "
                            >{{ error }}</span
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="mb-3 position-relative">
                        <label for="validation">ตรอก :</label>
                        <input
                          v-model="UpdateAddress.subLaneU"
                          type="text"
                          class="form-control"
                          placeholder="ตรอก"
                          :class="{
                            'is-invalid':
                              submitUpdateAddress &&
                              $v.UpdateAddress.subLane.$error,
                          }"
                        />
                        <div
                          v-if="
                            submitUpdateAddress &&
                              $v.UpdateAddress.subLaneU.$error
                          "
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.UpdateAddress.subLaneU.required">{{
                            error
                          }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- end rows 1 -->

                  <div class="row">
                    <!-- <div class="col-lg-2">
                      <div class="mb-3 position-relative">
                        <code>* </code
                        ><label for="validation">หมายเลขห้อง :</label>
                        <input
                          v-model="UpdateAddress.roomnumberU"
                          type="text"
                          class="form-control"
                          placeholder="เลขห้อง"
                          :class="{
                            'is-invalid':
                              submitformAddress &&
                              $v.UpdateAddress.roomnumberU.$error,
                          }"
                        />
                        <div
                          v-if="
                            submitformAddress &&
                            $v.UpdateAddress.roomnumberU.$error
                          "
                          class="invalid-tooltip"
                        >
                          <span
                            v-if="
                              !$v.UpdateAddress.roomnumberU.minValue ||
                              !$v.UpdateAddress.roomnumberU.maxValue
                            "
                            >{{ error }}</span
                          >
                        </div>
                      </div>
                    </div> -->

                    <div class="col-lg-5">
                      <div class="mb-3 position-relative">
                        <label for="validation">ชื่อหมู่บ้าน :</label>
                        <input
                          v-model="UpdateAddress.townshipNameU"
                          type="text"
                          class="form-control"
                          placeholder="ชื่อหมู่บ้าน"
                          :class="{
                            'is-invalid':
                              submitUpdateAddress &&
                              $v.UpdateAddress.townshipNameU.$error,
                          }"
                        />
                        <div
                          v-if="
                            submitUpdateAddress &&
                              $v.UpdateAddress.townshipNameU.$error
                          "
                          class="invalid-tooltip"
                        >
                          <span
                            v-if="!$v.UpdateAddress.townshipNameU.required"
                            >{{ error }}</span
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-3">
                      <div class="mb-3 position-relative">
                        <label for="validation">ซอย :</label>
                        <input
                          v-model="UpdateAddress.laneU"
                          type="text"
                          class="form-control"
                          placeholder="ซอย"
                          :class="{
                            'is-invalid':
                              submitUpdateAddress &&
                              $v.UpdateAddress.laneU.$error,
                          }"
                        />
                        <div
                          v-if="
                            submitUpdateAddress && $v.UpdateAddress.laneU.$error
                          "
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.UpdateAddress.laneU.maxlength">{{
                            error
                          }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--End rows 2 -->

                  <div class="row">
                    <div class="col-lg-4">
                      <div class="mb-3 position-relative">
                        <label for="validation">ถนน :</label>
                        <input
                          v-model="UpdateAddress.streetNameU"
                          type="text"
                          class="form-control"
                          placeholder="ชื่อถนน"
                          :class="{
                            'is-invalid':
                              submitUpdateAddress &&
                              $v.UpdateAddress.streetNameU.$error,
                          }"
                        />
                        <div
                          v-if="
                            submitUpdateAddress &&
                              $v.UpdateAddress.streetNameU.$error
                          "
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.UpdateAddress.streetName.maxlength">{{
                            error
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-4">
                      <div class="mb-3 position-relative">
                        <code>* </code><label for="provinceId">จังหวัด :</label>
                        <multiselect
                          v-model="UpdateAddress.provinceIdU"
                          track-by="nameTh"
                          @input="getAmphuresU"
                          :options="UpdateAddress.provinces"
                          label="nameTh"
                          :class="{
                            'is-invalid':
                              submitUpdateAddress &&
                              $v.UpdateAddress.provinceIdU.$error,
                          }"
                        >
                          <!-- <template slot="singleLabel" slot-scope="{ option }"
                              ><strong>{{ option.nameTh }}</strong></template
                            > -->
                        </multiselect>
                        <div
                          v-if="
                            submitUpdateAddress &&
                              $v.UpdateAddress.provinceIdU.$error
                          "
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.UpdateAddress.provinceIdU.required">
                            {{ error }}</span
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-4">
                      <div class="mb-3 position-relative">
                        <code>* </code><label for="amphureId">อำเภอ :</label>
                        <multiselect
                          v-model="UpdateAddress.amphurIdU"
                          track-by="nameTh"
                          :options="UpdateAddress.amphures"
                          @input="getDistrictsU"
                          label="nameTh"
                          :class="{
                            'is-invalid':
                              submitUpdateAddress &&
                              $v.UpdateAddress.amphurIdU.$error,
                          }"
                        >
                        </multiselect>
                        <div
                          v-if="
                            submitUpdateAddress &&
                              $v.UpdateAddress.amphurIdU.$error
                          "
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.UpdateAddress.amphurIdU.required">
                            {{ error }}</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- End Rows-->

                  <div class="row">
                    <div class="col-lg-4">
                      <div class="mb-3 position-relative">
                        <code>* </code><label for="districtId">ตำบล :</label>
                        <multiselect
                          v-model="UpdateAddress.districtIdU"
                          track-by="nameTh"
                          :options="UpdateAddress.districts"
                          label="nameTh"
                          :class="{
                            'is-invalid':
                              submitUpdateAddress &&
                              $v.UpdateAddress.districtIdU.$error,
                          }"
                        >
                        </multiselect>
                        <div
                          v-if="
                            submitUpdateAddress &&
                              $v.UpdateAddress.districtIdU.$error
                          "
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.UpdateAddress.districtIdU.required">
                            {{ error }}</span
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-5">
                      <div class="mb-3 position-relative">
                        <label for="validation">รหัสไปรษณีย์ :</label>
                        <input
                          maxlength="5"
                          v-model="UpdateAddress.postCodeU"
                          type="text"
                          class="form-control"
                          placeholder="รหัสไปรษณีย์"
                          :class="{
                            'is-invalid':
                              submitUpdateAddress &&
                              $v.UpdateAddress.postCode.$error,
                          }"
                        />
                        <div
                          v-if="
                            submitUpdateAddress &&
                              $v.UpdateAddress.postCodeU.$error
                          "
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.UpdateAddress.postCodeU.required">{{
                            error
                          }}</span>
                        </div>
                      </div>
                    </div>
                    {{ UpdateAddress.fullAddressU }}
                  </div>

                  <div class="text-end">
                    <button type="submit" class="btn btn-primary">
                      บันทึก
                    </button>
                  </div>
                </form>
              </div>
            </b-modal>
          </div>
        </b-skeleton-wrapper>
      </div>
    </div>
  </Layout>
</template>
